/* src/App.css */
body, html, #root, .app {
  height: 100%;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  background-color: #4CAF50; /* Green background */
  padding: 30px 20px; /* Some padding */
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out items */
}

.logo-container {
  flex-shrink: 0; /* Prevent the logo from shrinking */
}

.logo {
  height: 100px; /* Adjust logo size as needed */
  width: auto;
}

.nav {
  display: flex;
  justify-content: center; /* Center the navigation links */
  gap: 20px; /* Add some space between the links */
}

.nav-link {
  color: white; /* White text color */
  text-decoration: none; /* Remove underline */
  font-size: 18px; /* Increase font size */
  padding: 10px 15px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for background color */
  font-weight: bold;
}

.nav-link:hover {
  background-color: #45a049; /* Darker green background on hover */
}
.title{
  text-align: center;
}

.content {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vh;
}

.lists-container {
  display: flex;
  flex-direction: column; /* Change to column layout for mobile */
  justify-content: space-around;
  padding: 20px;
}

.list-component {
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%; /* Full width on mobile */
  margin-bottom: 20px; /* Add margin between list components */
}

.list-item {
  margin: 10px 0;
}
.listdesc{
  color:blue;
}
.pub{
  color: gray;
}
.red{
  color:red;
}

.listStyle {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.listStyle:hover {
  color: green;
}

.footer {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0; /* Add padding for better mobile view */
}

.footer p {
  margin-top: 0; /* Remove top margin */
  padding-top: 10px; /* Adjust padding */
}

/* Media Queries for Responsive Design */

/* For tablets and above */
@media (min-width: 600px) {
  .lists-container {
    flex-direction: row; /* Row layout for larger screens */
  }

  .list-component {
    width: 45%; /* Adjust width for larger screens */
  }
}

/* For desktop and above */
@media (min-width: 1024px) {
  .header {
    padding: 30px 20px; /* Adjust padding for larger screens */
    height: 60px; /* Adjust height for larger screens */
  }

  .nav-link {
    font-size: 18px; /* Adjust font size for larger screens */
    padding: 10px 15px; /* Adjust padding for larger screens */
  }

  .lists-container {
    padding: 20px; /* Adjust padding for larger screens */
  }

  .footer {
    height: auto; /* Adjust height for larger screens */
    padding: 20px 0; /* Remove padding */
  }

  .footer p {
    margin-top: 0; /* Adjust margin */
    padding-top: 10px; /* Adjust padding */
  }
}
